import * as React from "react";
import Layout from "../layouts";
import {graphql} from "gatsby";
import HomeContent from "../components/HomeContent";
import SkillsContent from "../components/SkillsContent";
import ProjectsContent from "../components/ProjectsContent";
import ContactContent from "../components/ContactContent";
import Seo from "../components/Seo";

const HomePage = ({data, pageContext, location}) => {
    const {lang, slug} = pageContext;
    const {frontmatter, content} = data.homeContent.childMarkdownRemark;
    const skills = data.skills.nodes;
    const projects = data.projects.nodes;
    const {frontmatter: contactFrontmatter, content: contactContent} = data.contactContent.childMarkdownRemark;

    return (
        <Layout lang={lang}>
            <Seo lang={lang} title={frontmatter.subtitle} description={frontmatter.description} slug={slug} location={location}/>
            <HomeContent content={content} {...frontmatter}  />
            <SkillsContent skills={skills} title={frontmatter.skillsTitle}/>
            <ProjectsContent projects={projects} title={frontmatter.projectsTitle}/>
            <ContactContent content={contactContent} {...contactFrontmatter} />
        </Layout>
    )

}

export const query = graphql`
query HomeContent($lang: String) {
  homeContent: file(
    sourceInstanceName: {eq: "pages"}
    relativeDirectory: {eq: "home"}
    name: {regex: "/index.*/"}
    childrenMarkdownRemark: {elemMatch: {frontmatter: {lang: {eq: $lang}}}}
  ) {
    childMarkdownRemark {
      frontmatter {
        title
        subtitle
        description
      }
      content: html
    }
  }
  contactContent: file(
    sourceInstanceName: {eq: "pages"}
    relativeDirectory: {eq: "home"}
    name: {regex: "/contact.*/"}
    childrenMarkdownRemark: {elemMatch: {frontmatter: {lang: {eq: $lang}}}}
  ) {
    childMarkdownRemark {
      frontmatter {
        title
        tagLine
      }
      content: html
    }
  }
  skills: allFile(
    filter: {sourceInstanceName: {eq: "skills"}, relativeDirectory: {eq: $lang}}
    sort: {fields: name}
  ) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          name
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                quality: 50
                width: 200
              )
            }
          }
        }
        content: html
      }
    }
  }
  projects: allFile(
    filter: {sourceInstanceName: {eq: "projects"}, relativeDirectory: {eq: $lang}}
    sort: {fields: name}
  ) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          title
          subtitle
          appStoreLink
          playStoreLink
          link
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                quality: 100
                width: 450
              )
            }
          }
        }
        content: html
      }
    }
  }
}
`

export default HomePage
