import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import CTAButton from "./CTAButton";
import {useIntl} from "react-intl";

const sendMail = () => {
    window.location = 'mailto:contact@stefangaller.at'
}

const HomeContent = ({title, subtitle, content}) => {

    const intl = useIntl()

    return (
        <section id="home" className="mb-24">
            <div className="flex flex-row justify-between relative">
                <div className="flex flex-col mt-56 xs:mt-64 sm:mt-96 w-full md:w-auto">
                    <h1 className="flex flex-col">
                        <span className="text-3xl md:text-5xl font-bold md:my-1">{`${title} `}</span>
                        <span className="text-2xl md:text-4xl font-thin">{subtitle} </span>
                    </h1>
                    <div className="text-xl md:text-2xl my-6" dangerouslySetInnerHTML={{__html: content}}/>
                    <CTAButton text={intl.formatMessage({id: 'contactCTA'})} onClick={() => sendMail()}/>
                </div>
                <div
                    className="absolute xl:relative -right-16 sm:-right-24 lg:-right-16 xl:right-0 w-10/12 md:w-8/12 max-w-3xl">
                    <StaticImage
                        src="../content/images/me.png"
                        quality={90}
                        placeholder="none"
                        alt="photo of stefan galler"/>
                </div>
            </div>
        </section>
    )
}

export default HomeContent
